import React from "react";

const HomeIcon = ({ fillColor, styles }) => {
  return (
    <svg
      className={styles}
      width="78"
      height="55"
      viewBox="0 0 78 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M75.5978 27.3935L40.6126 1.42732C40.3607 1.23985 40.0615 1.09112 39.7321 0.989647C39.4027 0.888171 39.0496 0.835938 38.693 0.835938C38.3364 0.835938 37.9833 0.888171 37.6539 0.989647C37.3245 1.09112 37.0253 1.23985 36.7734 1.42732L1.78821 27.3935C0.768973 28.1506 0.191406 29.1789 0.191406 30.2513C0.191406 32.4783 2.62908 34.2888 5.62732 34.2888H9.31356V52.8172C9.31356 53.9338 10.5281 54.8359 12.0315 54.8359H33.2571V40.7047H42.7699V54.8359H65.3545C66.8579 54.8359 68.0724 53.9338 68.0724 52.8172V34.2888H71.7587C73.2026 34.2888 74.5871 33.8661 75.6063 33.1028C77.7212 31.5257 77.7212 28.9707 75.5978 27.3935Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default HomeIcon;
