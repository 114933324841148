import FormPage from "./pages/formExample";

const formExample = [
  {
    path: "/form",
    element: <FormPage />,
  },
];

export default formExample;
