// src/reports/ReportIcon.js
import React from "react";

const ReportIcon = ({ fillColor = "white", styles = "" }) => {
  return (
    <svg
      className={styles}
      width="57"
      height="62"
      viewBox="0 0 57 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.2144 4.14635L33.437 4.58219L55.3433 51.0673C55.8493 52.141 56.1151 53.3522 56.1151 54.5842C56.1151 58.3809 53.6439 61.4887 50.5167 61.7411L50.1052 61.7576H6.29264C5.26053 61.7576 4.24581 61.4404 3.34625 60.8364C0.584863 58.9824 -0.475502 54.9129 0.849089 51.5436L1.05461 51.0673L22.9609 4.58219C23.4234 3.60071 24.0719 2.76533 24.8519 2.14107L25.2525 1.84684L25.6708 1.59081C28.3617 0.0973258 31.5795 1.18424 33.2144 4.14635ZM28.1984 7.00765L5.86507 55.6743H50.5317L28.1984 7.00765ZM30.9901 46.5493V52.6327H25.4067V46.5493H30.9901ZM28.1984 22.216C29.7402 22.216 30.9901 23.5778 30.9901 25.2576V40.466C30.9901 42.1458 29.7402 43.5076 28.1984 43.5076C26.6566 43.5076 25.4067 42.1458 25.4067 40.466V25.2576C25.4067 23.5778 26.6566 22.216 28.1984 22.216Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default ReportIcon;
