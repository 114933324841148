import React from "react";

const InvitesIcon = ({ fillColor, styles }) => {
  return (
    <svg
      className={styles}
      width="68"
      height="74"
      viewBox="0 0 68 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50.9414 0.335938C53.5049 0.335765 55.9715 1.52148 57.8366 3.65048C59.7017 5.77947 60.8243 8.69082 60.9747 11.7888L60.9914 12.5026V30.1443L62.9813 29.0736C65.0382 27.9664 67.41 29.6819 67.6713 32.3099L67.6914 32.7804V65.2248C67.6919 67.2712 67.0535 69.2421 65.9042 70.7426C64.7548 72.2431 63.1795 73.1622 61.4939 73.3157L60.9914 73.3359H7.39141C5.70108 73.3366 4.07301 72.5637 2.83357 71.1723C1.59413 69.7809 0.834931 67.8737 0.708157 65.8332L0.691406 65.2248V32.7804C0.691406 30.055 2.95266 28.1287 5.03636 28.9073L5.40486 29.0736L7.39141 30.1443V12.5026C7.39126 9.39925 8.37069 6.41313 10.1293 4.15521C11.8879 1.8973 14.2928 0.538279 16.8518 0.356216L17.4414 0.335938H50.9414ZM50.9414 8.44705H17.4414C16.5529 8.44705 15.7008 8.87433 15.0726 9.63489C14.4444 10.3955 14.0914 11.427 14.0914 12.5026V33.7497L34.1914 44.5658L54.2914 33.7497V12.5026C54.2914 11.427 53.9385 10.3955 53.3102 9.63489C52.682 8.87433 51.8299 8.44705 50.9414 8.44705ZM34.1914 20.6137C35.0453 20.6149 35.8665 21.0107 36.4874 21.7203C37.1083 22.4299 37.4819 23.3997 37.5319 24.4316C37.582 25.4635 37.3046 26.4796 36.7566 27.2723C36.2086 28.0649 35.4312 28.5743 34.5834 28.6964L34.1914 28.7248H27.4914C26.6376 28.7237 25.8163 28.3279 25.1954 27.6183C24.5745 26.9087 24.2009 25.9388 24.1509 24.9069C24.1008 23.875 24.3782 22.859 24.9262 22.0663C25.4742 21.2736 26.2516 20.7642 27.0995 20.6421L27.4914 20.6137H34.1914Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default InvitesIcon;
